export default {
  welcome: 'Welcome to Ducky',
  terms: `MARKDOWN:
If you were invited here by an organisation, they’re involving you in an ambitious campaign to learn about sustainability and help the organisation and our society to change. 

Ducky will use future profits for tools that make it possible to calculate the environmental impact and collaborate with friends and colleagues on powerful action.

By continuing, you consent to Ducky storing your name and email, and storing and structuring information regarding you and your habits. Your organisation (employer, school etc.) will not have access to your personal information, but will have access to anonymized data gathered during the campaign. The purpose of this data collection is researching and planning sustainability initiatives in the organisation.

If organisers are part of the competition themselves, they might see your personal savings and activities logged in the web app like any other member of your team.

See the [full Privacy Policy here](https://www.ducky.eco/en/support/privacy-policy).
`,
};
